import React from "react"
import "bootstrap/dist/css/bootstrap.css"
import ama from "../images/ama.png"

const d = new Date()
const Footer = () => (
  <footer className=" bg-light">
    <div className="container">
      <div className="row py-5">
        {/* <div className="col-md-4 text-center amwa_footer pb-3">
          <img className="ama" src={ama} alt="american medical writers association"/>
        </div> */}
        <div className="col-md-12 text-center pb-3">
            &copy; Pawprint Communications. {d.getFullYear()}.{" "}
          
        </div>
        <div className="col-md-12 text-center">
            <a
              className="backlink"
              target="_blank"
              href="https://www.getamplifydigital.com"
              rel="noopener noreferrer"
            >
              Website Designed & Developed by{" "}
              <img
                className="backlink_logo"
                alt="Amplify Digital"
                src="https://www.getamplifydigital.com/external_AD_dark.png"
              />
            </a>
      
        </div>
      </div>
    </div>
  </footer>
)

export default Footer
