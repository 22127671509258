import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import 'bootstrap/dist/css/bootstrap.css';
import pawprint from "../images/logo7.svg"


const Header = ({ siteTitle }) => (
  <header className="container-fluid floater ">
    <div className="navbar">
    <Link to="/"><img className="logo" src={pawprint} alt="pawprint communications - medical writing and veterinary research"/></Link>
    {/* <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
    <span className="navbar-toggler-icon"></span>
  </button>
  <div className="collapse navbar-collapse" id="navbarText">
    <ul className="navbar-nav ml-auto">
      <li className="nav-item">
        <Link className="nav-link pb-0" to="/" activeClassName="active">Home <span className="sr-only">(current)</span></Link>
      </li>
      <li className="nav-item">
        <Link className="nav-link" to="/about/"activeClassName="active">About</Link>
      </li>
      <li className="nav-item">
        <Link className="nav-link" to="/services/"activeClassName="active">Services</Link>
      </li>
      <li className="nav-item">
        <Link className="nav-link" to="/contact/"activeClassName="active">Contact</Link>
      </li>
      <li className="nav-item">
        <a className="nav-link" href="#">Blog</a>
      </li>
    </ul>
   
  </div> */}
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
