/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Footer from "./footer"
import Header from "./header"
import Contact from "./contact"
import { push as Menu } from "react-burger-menu"
import { Link } from "gatsby"

import "./layout.css"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <div id="outer-container">
        <Menu
          right
          pageWrapId={"page-wrap"}
          outerContainerId={"outer-container"}
        >
          <Link className="nav-link" to="/" activeClassName="active">
            Home <span className="sr-only">(current)</span>
          </Link>

          <Link className="nav-link" to="/about/" activeClassName="active">
            About
          </Link>

          <Link className="nav-link" to="/services/" activeClassName="active">
            Services
          </Link>

          <Link className="nav-link" to="/contact/" activeClassName="active">
            Contact
          </Link>
          {/* <a className="nav-link" href="https://blog.pawprintmedicalwriting.com">Blog</a> */}

        </Menu>
        <div id="page-wrap">
        <Header />

        <main>{children}</main>
        <Contact />
        <Footer />
        </div>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
