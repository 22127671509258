import React, {Component} from "react"
import "bootstrap/dist/css/bootstrap.css"
import Fade from 'react-reveal/Fade';


import $ from 'jquery'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const options = {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    newestOnTop:true,
    pauseOnHover: true,
    draggable: true,
    pauseOnVisibilityChange:true,
    rtl:false
  };
  
  
  class Contact extends Component {
  
    constructor(props){
      super(props);
  
      this.state = {
        firstname:'',
        lastname:'',
        email:'',
        topic:'',
        mesg:''
      };
      this.handleSubmit = this.handleSubmit.bind(this);
  
  
  
  }
  
  
  
  
  handleChange = (e) => {
    this.setState({[e.target.id]: e.target.value});
    // console.log(this.state);
  }
  
  contactError(err) {
    toast.error('There was an error sending your message. Please try again.', options);
  
  }
  
  contactSuccess(response) {
    toast.success(response, options);
  
    this.setState({
        firstname:'',
        lastname:'',
        email:'',
        topic:'',
        mesg:''
    });
  }
  
  
  
  handleSubmit(e) {
    e.preventDefault();
    
    const g = this;
  
    $.ajax({
      data: this.state,
      type: 'POST',
      url: '/mailer.php',
      success: function(data) {
        //console.log(data)
        g.contactSuccess(data);
      },
      error: function(xhr, status, err) {
        console.error(status, err.toString());
        console.error(status);
        console.error(err);
  
        g.contactError(err);
        
      }
    });
  
  
    // fetch('http://www.getamplifydigital.com/mailer.php',{
    //   method: 'POST',
    //   mode:'no-cors',
    //   // headers: {
    //   //   'Content-Type': 'multipart/form-data' 
    //   // },
    //   body: JSON.stringify(this.state)
    //   })
    //   .then(function(data) {
  
    //   // this.setState({
    //   //   firstName:'',
    //   //   lastName:'',
    //   //   em:'',
    //   //   phone:'',
    //   //   sub:'',
    //   //   body:''
    //   // });
  
  
    //    console.log('request succeeded with JSON response', data)
    //  });
  
    }
  
  render(){
    return (
  <div className="pattern border_top">
      <ToastContainer/>
    <div className="container py-5">
        <Fade bottom>
      <h2 className="pb-5 text-center">Let's Work Together</h2>

      <form id="contact-form" onSubmit={this.handleSubmit}>
        <div className="controls">
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label for="firstname">First Name</label>
                <input
                  id="firstname"
                  type="text"
                  name="firstname"
                  className="form-control"
                  placeholder="Please enter your first name *"
                  required="required"
                  onChange={this.handleChange} 
                  value={this.state.firstname}
                  data-error="First name is required."
                />
                <div className="help-block with-errors"></div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label for="lastname">Last Name</label>
                <input
                  id="lastname"
                  type="text"
                  name="lastname"
                  className="form-control"
                  placeholder="Please enter your last name *"
                  required="required"
                  onChange={this.handleChange} 
                  value={this.state.lastname}
                  data-error="Last name is required."
                />
                <div className="help-block with-errors"></div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label for="email">Email</label>
                <input
                  id="email"
                  type="email"
                  name="email"
                  className="form-control"
                  placeholder="Please enter your email *"
                  required="required"
                  onChange={this.handleChange}
                  value={this.state.email}
                  data-error="Valid email is required."
                />
                <div className="help-block with-errors"></div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label for="topic">Please Specify Your Need</label>
                <select
                  id="topic"
                  name="topic"
                  className="form-control"
                  required="required"
                  onChange={this.handleChange} 
                  value={this.state.topic}
                  data-error="Please specify your need."
                >
                  <option value="">Choose one...</option>
                  <option value="Professional Materials">Professional Materials</option>
                  <option value="Pet Parent Education">Pet Parent Education</option>
                  <option value="Medical Research">Medical Research</option>
                  <option value="Other">Other</option>
                </select>
                <div className="help-block with-errors"></div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <label for="mesg">Message</label>
                <textarea
                  id="mesg"
                  name="mesg"
                  className="form-control"
                  placeholder="Message for me *"
                  rows="4"
                  required="required"
                  onChange={this.handleChange}
                  value={this.state.mesg}
                  data-error="Please, leave us a message."
                ></textarea>
                <div className="help-block with-errors"></div>
              </div>
            </div>
            <div className="col-md-12 text-center">
              <input type="submit" className="btn btn-primary px-5" value="Send" />
            </div>
          </div>
        </div>
      </form>
      </Fade>
    </div>
  </div>
)}
    }

export default Contact
